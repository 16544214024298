const SidebarNavLinks: any[] = [
  {
    icon: "/sidebar/ico_home.svg",
    title: "Home",
    path: "/dashboard/home",
  },
  {
    icon: "/sidebar/ico_music_video.svg",
    title: "Music Videos",
    path: "/dashboard/music-videos",
  },
  {
    icon: "/sidebar/ico_official_audio.svg",
    title: "Official Audio",
    path: "/dashboard/official-audio",
  },
  {
    icon: "/sidebar/ico_artists.svg",
    title: "Artists",
    path: "/dashboard/artists",
  },
  {
    icon: "/sidebar/ico_about_us.svg",
    title: "About US",
    path: "/dashboard/about-us",
  },
];
export default SidebarNavLinks;
