import Slide from "./slide";

const SlideCard = (props: any) => {
  const { slideElements, children } = props;
  return (
    <>
      <div className="rounded-b-2xl bg-[#5E5144] w-full">
        <Slide slideElements={slideElements} />
        <div className="p-4">{children}</div>
      </div>
    </>
  );
};
export default SlideCard;
