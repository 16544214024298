import React from "react";
import { useTheme } from "../../context/themeContext";
import { ToggleSwitch } from "flowbite-react";

const DarkModeToggle: React.FC = () => {
  const { darkMode, toggleDarkMode } = useTheme();

  return (
    <>
      <div className="flex justify-start items-center gap-4">
        <ToggleSwitch
          checked={darkMode}
          label={darkMode ? "Dark Mode" : "Light Mode"}
          color="indigo"
          onChange={toggleDarkMode}
        />
      </div>
    </>
  );
};

export default DarkModeToggle;
