const Subscribe = () => {
  return (
    <div className="max-w-screen-xl mx-auto relative z-[1] mt-8 pb-10 px-4">
      <div className="mt-24 max-w-[480px] mx-auto flex justify-between">
        <input
          type="email"
          className="rounded-l-3xl bg-[#D9D9D9] text-black py-2 text-center flex-grow max-lg:w-20"
          placeholder="Email"
        />
        <button className="px-6 lg:px-8 rounded-r-3xl bg-black text-white hover:bg-gray-800">
          Subscribe
        </button>
      </div>
      <div className="md:text-center mt-4 text-lg text-white">
        <p>
          Subscribe to our mailing list to be updated until we officially
          launch.
        </p>
      </div>
    </div>
  );
};
export default Subscribe;
