import React, { useEffect, useRef, useState } from "react";
import "./animatedText.css"; // Import the CSS for styles

const AnimatedText: React.FC = () => {
  const texts: string[] = [
    "Criminally Underrated",
    "They’re sleeping on you",
    "This deserves more views",
  ];

  const textContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let textIndex = 0;
    const animateCharacters = () => {
      let charIndex = 0;
      if (!textContainerRef.current) return;

      const currentText = textContainerRef.current.children[
        textIndex
      ] as HTMLElement;
      const previousText = textContainerRef.current.children[
        (textIndex - 1 + texts.length) % texts.length
      ] as HTMLElement;

      // Reset character index for the current text
      previousText?.classList?.add("active");
      currentText?.classList?.add("active");

      const animateNextCharacter = () => {
        if (charIndex < currentText.children.length) {
          const beforeChar = previousText.children[charIndex] as HTMLElement;
          const currentChar = currentText.children[charIndex] as HTMLElement;

          beforeChar?.classList?.add("hide");
          setTimeout(() => {
            beforeChar?.classList?.remove("hide");
            beforeChar?.classList?.add("inactive");
          }, 50);

          currentChar?.classList?.add("hide");
          setTimeout(() => {
            currentChar?.classList?.remove("hide");
            currentChar?.classList?.add("show");
          }, 50);

          charIndex += 1;
          setTimeout(animateNextCharacter, 50);
        } else {
          setTimeout(() => {
            previousText?.classList?.remove("active");
            currentText?.classList?.remove("active");
            Array.from(previousText.children).forEach((span) =>
              span?.classList?.remove("inactive")
            );
            Array.from(currentText.children).forEach((span) =>
              span?.classList?.remove("show")
            );
            textIndex = (textIndex + 1) % texts.length;
            animateCharacters();
          }, 1000);
        }
      };

      animateNextCharacter();
    };

    animateCharacters();
  }, []);

  return (
    <div
      className="relative w-full h-10 overflow-clip animate-section"
      ref={textContainerRef}
    >
      {texts.map((text, index) => (
        <div
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap overflow-clip text-ellipsis text"
          key={index}
        >
          {text.split("").map((char, i) => (
            <span key={i}>{char === " " ? "\u00A0" : char}</span>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AnimatedText;
