import { useState } from "react";

const NotificationItem = (props: any) => {
  const [data, setData] = useState({ ...props });
  return (
    <div className="flex justify-between items-center gap-4">
      <img
        src={data?.from?.cover}
        className="w-16 h-16 rounded-full flex-shrink-0 object-cover object-center"
      />
      <div
        className="flex-grow line-clamp-2 "
        dangerouslySetInnerHTML={{ __html: data?.description }}
      ></div>
      <div>
        {data?.type === "FOLLOW" && (
          <button className="px-8 rounded-2xl py-1 bg-white text-black font-bold">
            Follow
          </button>
        )}
        {data?.type !== "FOLLOW" && (
          <div className="relative">
            <img src={data?.content?.cover} className="w-20 aspect-video" />
            <button className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5 0C24.6717 0 29.6316 2.05446 33.2886 5.71142C36.9455 9.36838 39 14.3283 39 19.5C39 24.6717 36.9455 29.6316 33.2886 33.2886C29.6316 36.9455 24.6717 39 19.5 39C14.3283 39 9.36838 36.9455 5.71142 33.2886C2.05446 29.6316 0 24.6717 0 19.5C0 14.3283 2.05446 9.36838 5.71142 5.71142C9.36838 2.05446 14.3283 0 19.5 0ZM3.65625 19.5C3.65625 23.702 5.3255 27.7319 8.29678 30.7032C11.2681 33.6745 15.298 35.3438 19.5 35.3438C23.702 35.3438 27.7319 33.6745 30.7032 30.7032C33.6745 27.7319 35.3438 23.702 35.3438 19.5C35.3438 15.298 33.6745 11.2681 30.7032 8.29678C27.7319 5.3255 23.702 3.65625 19.5 3.65625C15.298 3.65625 11.2681 5.3255 8.29678 8.29678C5.3255 11.2681 3.65625 15.298 3.65625 19.5ZM15.5488 12.7408L25.9423 18.9784C26.0321 19.0326 26.1064 19.1091 26.158 19.2005C26.2096 19.2919 26.2367 19.3951 26.2367 19.5C26.2367 19.6049 26.2096 19.7081 26.158 19.7995C26.1064 19.8909 26.0321 19.9674 25.9423 20.0216L15.5488 26.2592C15.4564 26.3149 15.3508 26.345 15.243 26.3465C15.1351 26.348 15.0287 26.3209 14.9348 26.2678C14.8408 26.2148 14.7626 26.1377 14.7082 26.0446C14.6538 25.9514 14.6251 25.8455 14.625 25.7376V13.2649C14.6246 13.1568 14.653 13.0505 14.7073 12.957C14.7615 12.8635 14.8397 12.7861 14.9337 12.7328C15.0277 12.6794 15.1342 12.6521 15.2423 12.6535C15.3504 12.6549 15.4562 12.685 15.5488 12.7408Z"
                  fill="#D2C7C7"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationItem;
