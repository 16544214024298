import React, { forwardRef } from "react";

interface SvgColorProps extends React.HTMLProps<HTMLSpanElement> {
  src: string;
  color?: string; // Add a color prop
  sx?: React.CSSProperties;
}

const SvgColor = forwardRef<HTMLSpanElement, SvgColorProps>(
  ({ src, className, sx, color = "currentColor", ...other }, ref) => (
    <span
      ref={ref}
      {...other}
      className={className}
      style={{
        width: 24,
        height: 24,
        display: "inline-block",
        backgroundColor: color, // Use the color prop here
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
    />
  )
);

export default SvgColor;
