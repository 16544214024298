import { Link, NavLink } from "react-router-dom";
import classNames from "../constants/classNames";
import headerNavLinks from "./headerNavLinks";
import { useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <header className="py-4 px-4 bg-[#302D2A] border-b border-[#808080] fixed w-full top-0 left-0 z-10">
      <div
        className={`${classNames.containerFluidClass} flex justify-between items-center`}
      >
        <Link to={"/"}>
          <img src="/img/wizmeek_logo.png" className="h-7" alt="LOGO" />
        </Link>
        <button
          className="flex lg:hidden items-center justify-center text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
        <div
          className={`${
            isOpen ? "flex" : "hidden"
          } lg:flex max-lg:absolute max-lg:top-full max-lg:mt-[1px] max-lg:left-0 max-lg:w-full max-lg:bg-[#302D2A] max-lg:flex-col max-lg:py-4 max-lg:shadow max-lg:gap-2 justify-end items-center gap-5 transition-all`}
        >
          {headerNavLinks?.map((d: any, index: number) => (
            <NavLink
              key={index}
              to={d.path}
              className={({ isActive }) => {
                return `text-xl lg:text-2xl hover:text-gray-400 ${
                  isActive ? "text-gray-400" : "text-white "
                }`;
              }}
            >
              {d.title}
            </NavLink>
          ))}
        </div>
      </div>
    </header>
  );
};
export default Header;
