import Slide from "./slide";
import medias from "../../../data/medias.json";
import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import SlideCard from "./slideCard";
import { Helmet } from "react-helmet";
const Home = () => {
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const [slidesData, setSlidsData] = useState<any[]>([]);
  useEffect(() => {
    let slides: any[] = [];
    medias.map((d: any) => {
      let elem = <img src={d.cover} className="w-full" alt={d.title} />;
      let elems = [];
      elems.push(elem);
      elems.push(elem);
      elems.push(elem);
      elems.push(elem);
      slides.push(elems);
    });
    setSlidsData(slides);
  }, []);

  return (
    <>
      <Helmet>
        <title>Wizmeek</title>
        <meta
          name="description"
          content="Explore the world of indie music and discover talented artists who deserve more recognition. Find new music gems and support emerging artists."
        />
      </Helmet>
      <div>
        {!openItem ? (
          <>
            <h1 className="font-medium text-2xl">Wizmeek Highlights</h1>
            <div className="grid max-md:gird-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4 mt-4">
              <SlideCard slideElements={slidesData[0]}>
                <div className="flex justify-between">
                  <button className="flex-1 rounded-l-2xl py-1 bg-black text-white">
                    All Genres
                  </button>
                  <button className="flex-1 rounded-r-2xl py-1 bg-[#65A64E] text-white">
                    New
                  </button>
                </div>
              </SlideCard>
              <SlideCard slideElements={slidesData[1]}>
                <button className="rounded-2xl w-full py-1 bg-white text-black">
                  Artists
                </button>
              </SlideCard>
              <SlideCard slideElements={slidesData[2]}>
                <div className="flex justify-between">
                  <button className="flex-1 rounded-l-2xl py-1 bg-black text-white">
                    All Genres
                  </button>
                  <button className="flex-1 rounded-r-2xl py-1 bg-[#E56116] text-white">
                    Throwbacks
                  </button>
                </div>
              </SlideCard>
            </div>
          </>
        ) : (
          <MediaDetail setOpen={setOpenItem} data={data} />
        )}
        <div className="mt-6">
          <h1 className="font-medium text-2xl">Based on your Taste</h1>
          <div className="mt-8">
            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
              {medias.map((d: any, index: number) => (
                <div key={index}>
                  <MediaCard
                    {...d}
                    key={index}
                    onClick={() => handleClick(d)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
