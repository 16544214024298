import React, { useEffect, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const Editor = (props: any) => {
  const { sendMessage } = props;
  const [showPicker, setShowPicker] = useState(false);
  const [message, setMessage] = useState("");
  const addEmoji = (emoji: any) => {
    setMessage(message + emoji.native); // Append the selected emoji to the message
  };
  const editorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editorRef]);
  return (
    <div className="relative" ref={editorRef}>
      <div className=" absolute bottom-full w-full">
        {showPicker && <Picker data={data} onEmojiSelect={addEmoji} />}
      </div>
      <div className="flex justify-between items-center gap-3">
        <div className="relative flex-grow">
          <input
            className="rounded-2xl pr-16 py-3 pl-3 placeholder:text-gray-300 w-full border border-white border-opacity-30 bg-[#1F1D2BCC] focus:outline-0 text-sm"
            placeholder="Type your comment here..."
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage(message);
                setMessage("");
              } else {
              }
            }}
          />
        </div>
        <button
          className="absolute top-1/2 right-10 -translate-y-1/2"
          onClick={() => setShowPicker(!showPicker)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM13.5 6C14.328 6 15 6.672 15 7.5C15 8.328 14.328 9 13.5 9C12.672 9 12 8.328 12 7.5C12 6.672 12.672 6 13.5 6ZM6.5 6C7.328 6 8 6.672 8 7.5C8 8.328 7.328 9 6.5 9C5.672 9 5 8.328 5 7.5C5 6.672 5.672 6 6.5 6ZM10 15.5C7.304 15.5 5.066 13.559 4.595 11H15.405C14.934 13.559 12.696 15.5 10 15.5Z"
              fill="#6B7280"
            ></path>
          </svg>
        </button>
        <button
          className="absolute top-1/2 -translate-y-1/2 right-3 z-[1]"
          onClick={() => {
            sendMessage(message);
            setMessage("");
          }}
        >
          <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#6B7280"
          >
            <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Editor;
