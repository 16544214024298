import { Link } from "react-router-dom";
import classNames from "../constants/classNames";

const Footer = () => {
  return (
    <footer className="bg-black text-white px-4 py-4 relative z-[1]">
      <div className={`${classNames.containerFluidClass}`}>
        <div className="py-2 flex max-lg:flex-col max-lg:gap-6 justify-between items-center relative">
          <div className="lg:absolute top-0 left-0 w-full h-full flex justify-center items-center -z-0">
            <Link to={"/"} className="flex-shrink-0 flex-fill">
              <img src="/img/wizmeek_logo.png" className="h-7" alt="LOGO" />
            </Link>
          </div>
          <div className="relative z-10 flex max-lg:flex-col max-lg:gap-1 justify-center lg:justify-start lg:gap-5 max-lg:text-xl text-2xl flex-shrink-0 flex-fill">
            <ul className="max-lg:flex max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:gap-1">
              <li>
                <Link to={"/"} className="hover:text-gray-300">
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/content"} className="hover:text-gray-300">
                  Content
                </Link>
              </li>
            </ul>
            <ul className="max-lg:flex max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:gap-1">
              <li>
                <Link to={"/subscribe"} className="hover:text-gray-300">
                  Subscribe
                </Link>
              </li>
              <li>
                <Link to={"/submit"} className="hover:text-gray-300">
                  Submit
                </Link>
              </li>
            </ul>
            <ul className="max-lg:flex max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:gap-1">
              <li>
                <Link to={"/about-us"} className="hover:text-gray-300">
                  About Us
                </Link>
              </li>
              <li>
                <Link to={"/get-in-touch"} className="hover:text-gray-300">
                  Get in Touch
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex justify-end gap-5 items-center flex-shrink-0 flex-fill relative z-10">
            <a href="https://x.com/wizmeek_">
              <svg
                className="w-11 h-11 fill-white hover:fill-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61560930784646">
              <svg
                className="w-11 h-11 fill-white hover:fill-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
              </svg>
            </a>
            <a href="https://www.instagram.com/wizmeek_/?next=%2F">
              <svg
                className="w-11 h-11 fill-white hover:fill-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="border-t py-4 text-center border-[#252836] max-lg:px-8 mx-auto">
          Copyright © 2024 Wizmeek Inc. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};
export default Footer;
