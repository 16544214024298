import { Link } from "react-router-dom";
import classNames from "../../constants/classNames";
import { useState } from "react";
import TasteCard from "./tasteCard";

const MusicTaste = () => {
  const [data, setData] = useState<any[]>([
    { _id: 0, title: "POP", checked: true },
    { _id: 1, title: "DRLL", checked: false },
    { _id: 2, title: "BOOM BAP", checked: false },
    { _id: 3, title: "AFRO BEATS", checked: false },
    { _id: 4, title: "HIP HOP", checked: false },
    { _id: 5, title: "R&B", checked: false },
    { _id: 6, title: "ALTERNATIVE", checked: false },
    { _id: 7, title: "GANGSTA RAP", checked: false },
  ]);
  const handleClick = (d: any) => {
    let updatedData = [...data];
    updatedData.find((_d: any) => {
      _d._id == d._id ? (_d.checked = true) : (_d.checked = false);
    });
    setData(updatedData);
  };
  return (
    <div className="min-h-screen flex flex-col bg-[#302D2A]">
      <header className="py-4 px-4 bg-[#302D2A] w-full top-0 left-0 z-10">
        <div
          className={`${classNames.containerFluidClass} flex justify-between items-center`}
        >
          <Link to={"/"}>
            <img src="/img/wizmeek_logo.png" className="h-7" alt="LOGO" />
          </Link>
        </div>
      </header>
      <main className={`${classNames.containerClass} flex-grow py-8 space-y-4`}>
        <h1 className="text-center text-2xl text-white">
          Select Your Music Taste - At least3.
        </h1>
        <div className="py-10 max-md:w-80 mx-auto max-md:space-y-3">
          <div className="flex max-md:flex-col max-md:gap-3 justify-between">
            {data.slice(0, 3).map((d: any, index: number) => (
              <TasteCard key={index} {...d} onClick={() => handleClick(d)} />
            ))}
          </div>
          <div className="flex max-md:flex-col max-md:gap-3 justify-around">
            {data.slice(3, 5).map((d: any, index: number) => (
              <TasteCard key={index} {...d} onClick={() => handleClick(d)} />
            ))}
          </div>
          <div className="flex max-md:flex-col max-md:gap-3 justify-between">
            {data.slice(5, 8).map((d: any, index: number) => (
              <TasteCard key={index} {...d} onClick={() => handleClick(d)} />
            ))}
          </div>
        </div>
        <div className="text-center">
          <button className="bg-[#0066FF] text-[#5A5A5A] px-8 py-2.5 font-bold hover:opacity-70">
            Done
          </button>
        </div>
      </main>
    </div>
  );
};
export default MusicTaste;
