import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  sidebarOpen: false,
};

// Then, handle actions in your reducers:
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSidebarOpen: (state: any, action: PayloadAction<any>) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { setSidebarOpen } = globalSlice.actions;

export default globalSlice.reducer;
