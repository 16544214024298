import { useState } from "react";
import MediaCard from "../../components/global/mediaCard";
import medias from "../../data/medias.json";
import AnimatedText from "./animatedText";
import MediaDetail from "../../components/global/mediaDetail";
const Home = () => {
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="max-w-screen-xl mx-auto relative z-[1] mt-8 pb-10 px-4">
      {/* CONTENT */}
      {!openItem ? (
        <>
          <h2 className="md:text-center max-md:text-3xl text-4xl font-bold text-white leading-normal">
            At Wizmeek we highlight artists who deserve to be heard.
          </h2>
          <div className="max-w-screen-lg flex max-md:flex-col mx-auto justify-between text-2xl md:text-3xl font-semibold mt-4">
            <div className="flex-1 flex-shrink-0 py-0.5 flex justify-center items-center bg-[#D9D9D9] text-black">
              The Music Head Says..
            </div>
            <div className="flex-1 flex-shrink-0 py-0.5 flex justify-center items-center bg-black text-white max-md:!h-10">
              <AnimatedText />
            </div>
          </div>
          <div className="mt-8 max-w-[480px] mx-auto flex justify-between">
            <input
              type="email"
              className="rounded-l-3xl bg-[#D9D9D9] text-black py-2 text-center flex-grow max-lg:w-20"
              placeholder="Email"
            />
            <button className="px-6 lg:px-8 rounded-r-3xl bg-black text-white hover:bg-gray-800">
              Subscribe
            </button>
          </div>
          <div className="md:text-center mt-4 text-lg text-white">
            <p>
              Subscribe to our mailing list to be updated until we officially
              launch. <br />
              Meanwhile, check out the preview of the Wizmeek experience below.
            </p>
          </div>
        </>
      ) : (
        <>
          <MediaDetail setOpen={setOpenItem} data={data} />
        </>
      )}
      <div className="grid mt-12 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
        {medias.map((d: any, index: number) => (
          <div key={index}>
            <MediaCard onClick={() => handleClick(d)} {...d} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Home;
