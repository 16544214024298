import React, { useLayoutEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "../pages/home";
import DHome from "../pages/dashboard/home";
import Layout from "../layout/layout";
import NotFound from "../pages/404/404";
import DashboardLayout from "../layout/dashboard/layout";
import MusicVideos from "../pages/dashboard/music_videos";
import OfficialAudios from "../pages/dashboard/official_audios";
import Artists from "../pages/dashboard/artists";
import Notifications from "../pages/dashboard/notifications";
import Profile from "../pages/dashboard/profile";
import Content from "../pages/content";
import Subscribe from "../pages/subscribe";
import AboutUS from "../pages/about-us";
import Submit from "../pages/submit";
import GetInTouch from "../pages/get-in-touch";
import Account from "../pages/dashboard/account";
import MusicTaste from "../pages/music_taste";
const Wrapper = ({ children }: { children: any }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return children;
};
const Router = () => {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="content" element={<Content />} />
            <Route path="subscribe" element={<Subscribe />} />
            <Route path="about-us" element={<AboutUS />} />
            <Route path="submit" element={<Submit />} />
            <Route path="get-in-touch" element={<GetInTouch />} />
          </Route>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="home" element={<DHome />} />
            <Route path="music-videos" element={<MusicVideos />} />
            <Route path="official-audio" element={<OfficialAudios />} />
            <Route path="artists" element={<Artists />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="profile" element={<Profile />} />
            <Route path="account" element={<Account />} />
            <Route path="about-us" element={<AboutUS />} />
            <Route index element={<Navigate to="home" replace />} />
          </Route>
          <Route path="/music-taste" element={<MusicTaste />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
};
export default Router;
