import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";

const DashboardLayout = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <main className="lg:ml-80 pt-20 px-6 min-h-screen flex flex-col">
        <div className="flex-grow pb-10">
          <Outlet />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default DashboardLayout;
