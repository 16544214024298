import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="">
      <div className="border-t border-[#252836] px-4 lg:px-8 py-8 ">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
          <div className="space-y-6">
            <h2 className="text-black dark:text-white font-bold text-lg">
              About US
            </h2>
            <ul>
              <li className="text-[#6f6f78] hover:opacity-75">
                <Link to={"/dashboard/about-us"}>view more</Link>
              </li>
            </ul>
          </div>

          <div className="space-y-6">
            <h2 className="text-black dark:text-white font-bold text-lg">
              About US
            </h2>
            <ul className="space-y-2">
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Home</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Contact us</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>About us</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Privacy policy</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Terms</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Requests</Link>
              </li>
            </ul>
          </div>

          <div className="space-y-6">
            <h2 className="text-black dark:text-white font-bold text-lg">
              Content
            </h2>
            <ul className="space-y-2">
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Submit</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Advertising</Link>
              </li>
              <li className="text-[#6f6f78] hover:opacity-75 font-medium">
                <Link to={"/dashboard/home"}>Facebook</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t mt-4 py-4 border-[#252836] text-center text-sm text-[#6f6f78]">
          Copyright © 2024 Wizmeek inc. All Right Reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
