import { Modal } from "flowbite-react";
import { modalTheme } from "../../../constants/themes";
import SvgColor from "../../../components/custom/SvgColor";
import users from "../../../data/users.json";
const ModalShare = (props: any) => {
  const { open, setOpen } = props;
  return (
    <>
      <Modal show={open} theme={modalTheme} onClose={() => setOpen(false)}>
        <Modal.Header>
          <span className="text-3xl">Share</span>
        </Modal.Header>
        <Modal.Body>
          <div className="rounded-xl px-4 text-white text-lg max-w-[480px] mx-auto">
            <div className="relative">
              <input
                placeholder="Search"
                className="px-3 py-2 rounded-lg dark:bg-[#313131] text-sm w-full"
              />
              <button>
                <SvgColor
                  src="/icon/search.svg"
                  className="absolute top-1/2 right-2 -translate-y-1/2"
                  sx={{ width: "10px", height: "12px" }}
                />
              </button>
            </div>
            <div className="mt-8 space-y-6">
              {users?.map((d: any, index: number) => (
                <div key={index} className="flex justify-between items-center">
                  <div className="flex justify-start items-center gap-4">
                    <img
                      src={d.cover}
                      className="object-cover object-center w-16 h-16 rounded-full flex-shrink-0"
                    />
                    <span className="text-lg font-bold">{d.name}</span>
                  </div>
                  <button
                    className={` rounded-lg font-bold px-8 text-white py-1.5 ${
                      index === 0 ? "bg-[#0038FF]" : "bg-[#3F3E3E]"
                    }`}
                  >
                    Share
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalShare;
