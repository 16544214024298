const Submit = () => {
  return (
    <div className="px-4 text-black">
      <div className="max-w-screen-lg mx-auto space-y-4 rounded bg-[#e6dddd] mt-24 p-6">
        <h2 className="text-4xl font-semibold">Submit </h2>
        <p>
          We are currently accepting submissions from artists with a YouTube
          channel. The content must be audio or video only. Please send your
          submissions to: submit@wizmeek.com
        </p>
      </div>
    </div>
  );
};
export default Submit;
